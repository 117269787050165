// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-media-div {
  font-size: 2em;
}

.icon-button i {
  color: white;
}
.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
}

/* .facebook i {
  background-color: #3b5998;
}

.linkedin i {
  background-color: #0e76a8;
}

.github i {
  background-color: #333;
}

.gitlab i {
  background-color: #fca326;
}

.google i {
  background-color: #ea4335;
}

.twitter i {
  background-color: #1da1f2;
}

.instagram i {
  background-color: #E4405F;
} */

/*Hover Transition*/
/* .twitter i:hover, .google i:hover, .gitlab i:hover,
.github i:hover ,.linkedin i:hover ,.facebook i:hover, .instagram i:hover{
  background-color: black;
  transition: 0.3s ease-in;
} */

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/socialMedia/SocialMedia.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EAGzB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG;;AAEH,mBAAmB;AACnB;;;;GAIG;;AAEH,gBAAgB;AAChB;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".social-media-div {\n  font-size: 2em;\n}\n\n.icon-button i {\n  color: white;\n}\n.icon-button {\n  margin-bottom: 10px;\n}\n\n.icon-button i {\n  border-radius: 2.6rem;\n  cursor: pointer;\n  display: inline-block;\n  font-size: 1.3rem;\n  height: 2.6rem;\n  line-height: 2.6rem;\n  margin: 0 5px;\n  position: relative;\n  text-align: center;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  width: 2.6rem;\n  margin-bottom: 10px;\n}\n\n/* .facebook i {\n  background-color: #3b5998;\n}\n\n.linkedin i {\n  background-color: #0e76a8;\n}\n\n.github i {\n  background-color: #333;\n}\n\n.gitlab i {\n  background-color: #fca326;\n}\n\n.google i {\n  background-color: #ea4335;\n}\n\n.twitter i {\n  background-color: #1da1f2;\n}\n\n.instagram i {\n  background-color: #E4405F;\n} */\n\n/*Hover Transition*/\n/* .twitter i:hover, .google i:hover, .gitlab i:hover,\n.github i:hover ,.linkedin i:hover ,.facebook i:hover, .instagram i:hover{\n  background-color: black;\n  transition: 0.3s ease-in;\n} */\n\n/* Media Query */\n@media (max-width: 768px) {\n  .social-media-div {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
