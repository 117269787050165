// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education-main {
  width: 100%;
}

.basic-education {
  margin-left: 5%;
  margin-right: 5%;
}

.heading-div {
  display: flex;
}

.heading-div > * {
  flex: 1 1;
}

.heading-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-text-div {
  text-align: center;
}

.heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 100px;
}

.heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
}

@media (max-width: 1380px) {
  .heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .heading-sub-text {
    font-size: 20px;
  }

  .heading-div {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/education/EducationComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".education-main {\n  width: 100%;\n}\n\n.basic-education {\n  margin-left: 5%;\n  margin-right: 5%;\n}\n\n.heading-div {\n  display: flex;\n}\n\n.heading-div > * {\n  flex: 1;\n}\n\n.heading-img-div {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.heading-text-div {\n  text-align: center;\n}\n\n.heading-img-div > * {\n  max-width: 100%;\n  height: auto;\n}\n\n.heading-text {\n  font-size: 56px;\n  font-weight: 400;\n  font-family: \"Google Sans Medium\";\n  line-height: 1.1;\n  text-align: center;\n  margin-top: 100px;\n}\n\n.heading-sub-text {\n  font-size: 30px;\n  font-family: \"Google Sans Regular\";\n  text-align: center;\n}\n\n@media (max-width: 1380px) {\n  .heading-text {\n    font-size: 50px;\n    margin-top: 80px;\n  }\n  .heading-sub-text {\n    font-size: 25px;\n  }\n}\n\n@media (max-width: 768px) {\n  .heading-text {\n    font-size: 30px;\n    margin-top: 20px;\n  }\n\n  .heading-sub-text {\n    font-size: 20px;\n  }\n\n  .heading-div {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
