// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-accord {
  margin: 50px;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/experienceAccordion/ExperienceAccordion.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".experience-accord {\n  margin: 50px;\n}\n\n.accord-panel {\n  font-family: \"Google Sans Regular\";\n}\n\n@media (max-width: 768px) {\n  .experience-accord {\n    margin: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
