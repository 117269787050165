// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-main {
  width: 100vw;
}

.error-main > .error-class {
  width: 90%;
  margin: auto 5%;
  /* min-height: calc(100vh - 192px); */
  text-align: center;
  display: block;
}

.error-main > .error-class > .error-404 {
  font-size: 7em;
  overflow: hidden;
  padding-top: 10px 0;
  margin: 10px 0;
}

.error-main > .error-class p {
  font-size: 1.4em;
  margin-top: 0;
}

@media screen and (min-width: 1364px) {
  .error-main > .error-class > .error-404 {
    height: 175px;
    padding-top: 60px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/errors/error404/Error.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;EACf,qCAAqC;EACrC,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":[".error-main {\n  width: 100vw;\n}\n\n.error-main > .error-class {\n  width: 90%;\n  margin: auto 5%;\n  /* min-height: calc(100vh - 192px); */\n  text-align: center;\n  display: block;\n}\n\n.error-main > .error-class > .error-404 {\n  font-size: 7em;\n  overflow: hidden;\n  padding-top: 10px 0;\n  margin: 10px 0;\n}\n\n.error-main > .error-class p {\n  font-size: 1.4em;\n  margin-top: 0;\n}\n\n@media screen and (min-width: 1364px) {\n  .error-main > .error-class > .error-404 {\n    height: 175px;\n    padding-top: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
