// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certs-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.certs-body-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1380px) {
  .certs-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .certs-body-div {
    flex-direction: column;
  }

  .certs-header {
    font-size: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/certifications/Certifications.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".certs-header {\n  font-size: 40px;\n  line-height: 1.1;\n  font-family: \"Google Sans Regular\";\n  text-align: center;\n}\n\n.certs-body-div {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n@media (max-width: 1380px) {\n  .certs-header {\n    font-size: 35px;\n  }\n}\n\n@media (max-width: 768px) {\n  .certs-body-div {\n    flex-direction: column;\n  }\n\n  .certs-header {\n    font-size: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
