// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-icons-languages {
  padding-left: 0;
  list-style: none;
  font-size: 2rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: right;
}

.software-skill-inline-languages {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0px;
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .software-skill-inline-languages {
    margin-right: 10px;
    margin-bottom: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/projectLanguages/ProjectLanguages.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF","sourcesContent":[".dev-icons-languages {\n  padding-left: 0;\n  list-style: none;\n  font-size: 2rem;\n  margin-bottom: 0px;\n  margin-top: 0px;\n  text-align: right;\n}\n\n.software-skill-inline-languages {\n  display: inline-block;\n  margin-right: 20px;\n  margin-bottom: 0px;\n  font-family: \"Google Sans Regular\";\n}\n\n@media (max-width: 768px) {\n  .software-skill-inline-languages {\n    margin-right: 10px;\n    margin-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
