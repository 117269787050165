// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-button {
  background-color: #55198b;
  border: solid 1px #55198b;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 13px 22px;
  margin-right: 0px;
  /* text-transform: uppercase; */
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
}
.main-button:hover {
  /* background-color: #ffffff; */
  /* color: black; */
  transition: ease-in 0.3s;
}
.project-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,kCAAkC;EAClC,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA,gBAAgB;AAChB;EACE;IACE,eAAe;IACf,kBAAkB;IAClB,iBAAiB;EACnB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".main-button {\n  background-color: #55198b;\n  border: solid 1px #55198b;\n  color: white;\n  font-weight: 500;\n  line-height: 1.1;\n  width: max-content;\n  padding: 13px 22px;\n  margin-right: 0px;\n  /* text-transform: uppercase; */\n  border-radius: 6px;\n  text-align: center;\n  text-decoration: none;\n  font-family: \"Google Sans Regular\";\n  display: block;\n  margin-top: 20px;\n  font-size: 18px;\n  cursor: pointer;\n  letter-spacing: 2px;\n}\n.main-button:hover {\n  /* background-color: #ffffff; */\n  /* color: black; */\n  transition: ease-in 0.3s;\n}\n.project-button {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n/* Media Query */\n@media (max-width: 768px) {\n  .main-button {\n    font-size: 15px;\n    padding: 12px 18px;\n    margin-right: 0px;\n  }\n}\n@media (max-width: 320px) {\n  .main-button {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
