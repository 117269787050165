// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_wrapper {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  /* background-color: #55198b; */
  overflow: hidden;
  border-radius: 100%;
  animation: grow 5.5s forwards;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  90% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
    border-radius: 100%;
  }
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/splash/Splash.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;EACrB;AACF;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".logo_wrapper {\n  height: 100vh;\n  width: 100vw;\n  align-items: center;\n  justify-content: center;\n}\n\n.screen {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.screen {\n  /* background-color: #55198b; */\n  overflow: hidden;\n  border-radius: 100%;\n  animation: grow 5.5s forwards;\n}\n\n@keyframes grow {\n  0% {\n    transform: scale(0);\n  }\n  10% {\n    transform: scale(1);\n    border-radius: 0%;\n    height: 100%;\n    width: 100%;\n  }\n  90% {\n    transform: scale(1);\n    border-radius: 0%;\n    height: 100%;\n    width: 100%;\n  }\n  100% {\n    transform: scale(0);\n    transform-origin: 50% 50%;\n    border-radius: 100%;\n  }\n}\n\n#logo {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
